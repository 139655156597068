












































































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import { Form } from "element-ui";
import { uuid } from "@/tool";
import {
  setTestPaper,
  getTestPaper,
  getTestPaperGroupGradeOptionList,
} from "@/api/testPaper";
import { uploadExamPapers } from "@/api/examPaper";
import { TestPaper } from "./index";
import { TestPaperGroup, TestPaperGroupGradeOption } from "@/tool/_class";
import { appTitle } from "@/utils/public-consts";
@Component({
  name: "TestEditor",
  components: {
    cardBox,
  },
})
export default class extends Vue {
  private rules = {
    testPaperFileName: [
      { required: true, message: "请上传试卷", trigger: "blur" },
    ],
    testPaperName: [
      { required: true, message: "请输入试卷名", trigger: "blur" },
    ],
    testPaperNo: [
      { required: true, message: "请输入试卷编号", trigger: "blur" },
    ],
    term: [{ required: true, message: "请输入试卷包名", trigger: "blur" }],
    grade: [{ required: true, message: "请输入年级", trigger: "blur" }],

    //term02: [{ required: true, message: "请输入学期", trigger: "blur" }],
  };
  private fileList: Array<{ name: string; url: string }> = [];
  private pageType: string = "";
  private title: string = "新增试卷";
  private ruleForm: TestPaper = {
    testPaperId: uuid(),
    testPaperName: "",
    testPaperNo: "",
    termNo: "",
    term: "",
    termNo02: "",
    term02: "",
    gradeNo: "",
    grade: "",
    testPaperFileName: "",
    testPaperFileUrl: "",
    testPaperFileHashCode: "",
  };
  private grades: TestPaperGroupGradeOption[] = [];
  private areaTags: string[] = ["广东", "深圳", "湖北", "宜昌", "吉林", "延边"];
  handleUploadRequest(params: any) {
    const file = params.file;
    let formData = new FormData();
    formData.append("file01", file, "file01.xsjz");
    uploadExamPapers(formData, {
      file01: file.name,
    })
      .then((res: any) => {
        this.fileList.splice(0, this.fileList.length);
        if (res.code == 0) {
          this.ruleForm.testPaperName = res.data.name;
          this.ruleForm.testPaperNo = res.data.name; //暂用
          this.ruleForm.testPaperFileName = res.data.fileName;
          this.ruleForm.testPaperFileUrl = res.data.fileUrl;
          this.ruleForm.testPaperFileHashCode = res.data.hashCode;
          this.$message({
            type: "success",
            message: "上传成功!",
          });
        }
      })
      .catch((error) => {
        this.fileList.splice(0, this.fileList.length);
        this.$message.error(`上传失败,${error.msg || error!.message || ""}`);
      });
  }

  private type(): string {
    if (this.pageType === "add") {
      this.title = "新增试卷库";
      this.$route.meta.title = "新增试卷库";
      document.title = `新增试卷库 | ${appTitle}`;
      return "立即创建";
    }
    if (this.pageType === "edit") {
      this.title = "编辑试卷";
      this.$route.meta.title = "编辑试卷";
      document.title = `编辑试卷 | ${appTitle}`;
      return "保 存";
    }
    return "立即创建";
  }
  private submitForm() {
    (this.$refs.ruleForm as Form).validate((valid: boolean) => {
      if (valid) {
        setTestPaper(this.ruleForm).then((res: any) => {
          if (res.code === 0) {
            this.$message({
              message: this.pageType === "add" ? "添加成功" : "保存成功",
              type: "success",
            });
          }
        });
      } else {
        return false;
      }
    });
  }
  private handleChangeGrade(value: string) {
    const idx: number = this.grades.findIndex((item: any) => {
      return item.gradeNo == value;
    });
    if (idx >= 0) {
      this.ruleForm.grade = this.grades[idx].grade;
    } else {
      this.ruleForm.grade = "";
    }
  }
  private doGoBackClick() {
    this.$router.go(-1);
  }
  private async __init() {
    this.grades.splice(0, this.grades.length);
    const { data } = await getTestPaperGroupGradeOptionList();
    data.items.forEach((element: TestPaperGroupGradeOption) => {
      this.grades.push(element);
    });

    console.log(`grades: ${JSON.stringify(this.grades)}`);
  }
  mounted() {
    this.__init();
    if (this.$route.query.testPaperId) {
      getTestPaper(this.$route.query.testPaperId as string).then((res: any) => {
        this.ruleForm = res.data;
      });
    }
    this.pageType = this.$route.query.type as string;
  }
}
